<template>
  <div class="print" style="overflow-y: hidden;">
    <div v-bind:key="page.id" v-for="page in pages" class="pageContainer" :style="[ pages.length > 1 ? {'margin-bottom': '150px'} : {'margin-bottom': '150px'}]">

      <div v-if="data.office!=null" style="text-align:center; margin-top: 20px; padding-top: 17px">
        <h4 class="mb-1">{{data.office.office_name}}</h4>
        <p class="mb-1" style="font-size:14px;">{{data.office.address}},  {{data.office.city.name}}, {{data.office.state.name}} {{data.office.zip}}   /   Phone: {{data.office.phone}} / Fax:  {{data.office.fax}}</p>
        <hr class="mt-1 mb-1">
      </div>
      
      <div class="mt-1" style="text-align:center"><h6>Bookkeeping Service Application</h6></div>

      <div class="row">
        <div class="col-12 bg-light border border-dark border-padding" style="margin-bottom:3px;"> 

          <table class="table-sm table table-border m-0">
            <tbody>
              <tr>
                <td style="font-weight: bold">Type:</td>
                <td style="font-weight: bold; color:#0e5499"><b v-if="data.type!=null">{{data.type.name}}</b></td>
                <td style="font-weight: bold">Payment Plan:</td>
                <td style="font-weight: bold; color:#0e5499"><b v-if="data.payment_plan!=null">{{data.payment_plan.name}}</b></td>
                <td style="font-weight: bold">Start On:</td>
                <td style="font-weight: bold; color:#0e5499"><b v-if="data.startOn!=null">{{formatDate(data.startOn.substr(0,10))}}</b></td>
                <td style="font-weight: bold">Fee:</td>
                <td style="font-weight: bold; color:#0e5499">${{data.fee}}</td>
              </tr>        
            </tbody>
          </table>

        </div>
      </div>

      <div class="row">
        <div class="col-12 bg-light border border-dark border-padding" style="margin-bottom:3px"> 

          <table class="table-sm table table-border m-0 pt-1">
            <tbody>
              <tr>
                <td colspan="1" style="font-weight: bold;padding-top:12px;">Corp. Name:</td>
                <td colspan="4" style="font-weight: bold; color:#0e5499; text-align:center;"><h6 style="padding-top:3px;">{{data.name}}</h6></td>
              </tr>
              <tr>
                <td colspan="1" class="bt" v-if="data.dba!=null" style="font-weight: bold;padding-top:12px;">DBA:</td>
                <td colspan="1" class="bt" v-else style="font-weight: bold;">DBA:</td>
                <td class="bt" colspan="4" style="font-weight: bold; color:#0e5499; text-align:center;"><h6 style="padding-top:3px;">{{data.dba}}</h6></td>
                <td class="bt" colspan="4"></td>
              </tr>
              <tr>
                <td class="bt" colspan="1" style="font-weight: bold;">Business Address:</td>
                <td class="bt" colspan="5" style="font-weight: bold; color:#0e5499">{{data.address}}</td>
              </tr>
              <tr>
                <td class="bt" style="font-weight: bold">City:</td>
                <td class="bt" style="font-weight: bold; color:#0e5499"><b v-if="data.city!=null">{{data.city.name}}</b></td>
                <td class="bt" style="font-weight: bold">State:</td>
                <td class="bt" style="font-weight: bold; color:#0e5499"><b v-if="data.state!=null">{{data.state.name}}</b></td>
                <td class="bt" style="font-weight: bold">Zip:</td>
                <td class="bt" style="font-weight: bold; color:#0e5499">{{data.zip}}</td>
              </tr>
              <tr>
                <td class="bt" style="font-weight: bold">Business Phone:</td>
                <td class="bt" colspan="3" style="font-weight: bold; color:#0e5499">{{data.phone}}</td>
                <td class="bt" colspan="1" style="font-weight: bold">Fax:</td>
                <td class="bt" colspan="1" style="font-weight: bold; color:#0e5499">{{data.fax}}</td>
              </tr>
              <tr>
                <td class="bt" colspan="2" style="font-weight: bold">Business Classification:</td>
                <td class="bt" colspan="4" style="font-weight: bold; color:#0e5499;"><b v-if="data.clasification!=null">{{data.clasification.name}}</b></td>
              </tr>
              <tr>
                <td class="bt" style="font-weight: bold; padding-top:12px;">Cert. of Authority:</td>
                <td class="bt" colspan="3" style="font-weight: bold; color:#0e5499; padding-top:12px;">{{data.certification}}</td>
                <td class="bt" colspan="1" style="font-weight: bold; padding-top:12px;">Federal Id:</td>
                <td class="bt" colspan="1" style="font-weight: bold; color:#0e5499;"><h6 style="padding-top:3px; font-weight: bold">{{data.federal}}</h6></td>
              </tr>
              <tr>
                <td class="bt" style="font-weight: bold; width:16.6%">Business Started:</td>
                <td class="bt" style="font-weight: bold; color:#0e5499; width:16.6%"><b v-if="data.started!=null">{{formatDate(data.started.substr(0,10))}}</b></td>
                <td class="bt" style="font-weight: bold; width:16.6%">Date of Incorporate:</td>
                <td class="bt" style="font-weight: bold; color:#0e5499; width:16.6%"><b v-if="data.incorporate!=null">{{formatDate(data.incorporate.substr(0,10))}}</b></td>
                <td class="bt" style="font-weight: bold; width:16.6%">Fiscal Year:</td>
                <td class="bt" style="font-weight: bold; color:#0e5499; width:16.6%">{{data.fiscalYear}}</td>
              </tr>
            </tbody>
          </table>

        </div>
      </div>

      <div class="row">
        <div  v-bind:key="shareholder.id" v-for="shareholder in page.slice(0, 3)" class="col-12 bg-light border border-dark border-padding"  style="margin-bottom:3px"> 
          <table class="table-sm table table-border m-0 pt-1">
            <tbody>
              <tr>
                <td style="font-weight: bold; width:16.6%">Name:</td>
                <td style="font-weight: bold; color:#0e5499; width:16.6%">{{shareholder.name}}</td>
                <td style="font-weight: bold; width:16.6%">Title:</td>
                <td style="font-weight: bold; color:#0e5499; width:16.6%"><b v-if="shareholder.title!=null">{{shareholder.title.name}}</b></td>
                <td style="font-weight: bold; width:16.6%">SS:</td>
                <td style="font-weight: bold; color:#0e5499; width:16.6%" >{{shareholder.securitySocial}}</td>
              </tr>
              <tr>
                <td class="bt" style="font-weight: bold">Home Address:</td>
                <td class="bt" style="font-weight: bold; color:#0e5499">{{shareholder.address}}</td>
                <td class="bt" style="font-weight: bold">Phone:</td>
                <td class="bt" style="font-weight: bold; color:#0e5499">{{shareholder.phone}}</td>
                <td class="bt" style="font-weight: bold">Date of Birth:</td>
                <td class="bt" style="font-weight: bold; color:#0e5499"><b v-if="shareholder.birthDay!=null">{{formatDate(shareholder.birthDay.substr(0,10))}}</b></td>
              </tr>        
              <tr>
                <td class="bt" style="font-weight: bold">City:</td>
                <td class="bt" style="font-weight: bold; color:#0e5499"><b v-if="shareholder.city!=null">{{shareholder.city.name}}</b></td>
                <td class="bt" style="font-weight: bold">State:</td>
                <td class="bt" style="font-weight: bold; color:#0e5499"><b v-if="shareholder.city!=null">{{shareholder.city.state.name}}</b></td>
                <td class="bt" style="font-weight: bold">Shares:</td>
                <td class="bt" style="font-weight: bold; color:#0e5499">{{shareholder.pivot.share}}%</td>
              </tr>
              <tr>
                <td class="bt" style="font-weight: bold">Email:</td>
                <td class="bt" colspan="3" style="font-weight: bold; color:#0e5499">{{shareholder.email}}</td>
                <td class="bt" style="font-weight: bold">Language:</td>
                <td class="bt" colspan="2" style="font-weight: bold; color:#0e5499">{{shareholder.languaje}}</td>
              </tr>
            </tbody>
          </table>

        </div>
      </div>

      <div class="row">
        <div class="col-12 bg-light border border-dark border-padding"  style="margin-bottom:3px"> 

          <table class="table-sm table table-border m-0 pt-1">
            <tbody>
              <tr>
                <td style="font-weight: bold; width:16.6%">Former Owner:</td>
                <td style="font-weight: bold; color:#0e5499; width:16.6%">{{page[3].name}}</td>
                <td style="font-weight: bold; width:16.6%">Phone:</td>
                <td style="font-weight: bold; color:#0e5499; width:16.6%">{{page[3].phone}}</td>
                <td style="font-weight: bold; width:16.6%">Fax:</td>
                <td style="font-weight: bold; color:#0e5499; width:16.6%">{{page[3].mobile}}</td>
              </tr>
              <tr>
                <td class="bt" style="font-weight: bold">Address:</td>
                <td class="bt" style="font-weight: bold; color:#0e5499">{{page[3].address}}</td>
                <td class="bt" style="font-weight: bold">City:</td>
                <td class="bt" style="font-weight: bold; color:#0e5499"><b v-if="page[3].city!=null">{{page[3].city.name}}</b></td>
                <td class="bt" style="font-weight: bold">State/Zip:</td>
                <td class="bt" style="font-weight: bold; color:#0e5499"><b v-if="page[3].city!=null">{{page[3].city.state.name}} / {{page[3].zip}}</b></td>
              </tr>
              <tr>
                <td class="bt" style="font-weight: bold">Email:</td>
                <td class="bt" colspan="3" style="font-weight: bold; color:#0e5499">{{page[3].email}}</td>
                <td class="bt" style="font-weight: bold">Note:</td>
                <td class="bt" colspan="2" style="font-weight: bold; color:#0e5499">{{page[3].note}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="row">
        <div class="col-12 bg-light border border-dark border-padding"  style="margin-bottom:3px"> 

          <table class="table-sm table table-border m-0 pt-1">
            <tbody>
              <tr>
                <td style="font-weight: bold; width:16.6%">LandLord:</td>
                <td style="font-weight: bold; color:#0e5499; width:16.6%">{{page[4].name}}</td>
                <td style="font-weight: bold; width:16.6%">Phone:</td>
                <td style="font-weight: bold; color:#0e5499; width:16.6%">{{page[4].phone}}</td>
                <td style="font-weight: bold; width:16.6%">Fax:</td>
                <td style="font-weight: bold; color:#0e5499; width:16.6%">{{page[4].mobile}}</td>
              </tr>
              <tr>
                <td class="bt" style="font-weight: bold">Address:</td>
                <td class="bt" style="font-weight: bold; color:#0e5499">{{page[4].address}}</td>
                <td class="bt" style="font-weight: bold">City:</td>
                <td class="bt" style="font-weight: bold; color:#0e5499"><b v-if="page[4].city!=null">{{page[4].city.name}}</b></td>
                <td class="bt" style="font-weight: bold">State/Zip:</td>
                <td class="bt" style="font-weight: bold; color:#0e5499"><b v-if="page[4].city!=null">{{page[4].city.state.name}} / {{page[4].zip}}</b></td>
              </tr>
              <tr>
                <td class="bt" style="font-weight: bold">Email:</td>
                <td class="bt" colspan="3" style="font-weight: bold; color:#0e5499">{{page[4].email}}</td>
                <td class="bt" style="font-weight: bold">Note:</td>
                <td class="bt" colspan="2" style="font-weight: bold; color:#0e5499">{{page[4].note}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="row">
        <div class="col-12 bg-light border border-dark border-padding"  style="margin-bottom:3px"> 
          <table class="table-sm table">
            <tbody>
              <tr>
                <td colspan="6"><b>Observations:</b></td>
              </tr>
              <tr style="height:50px;">
                <td colspan="6" style="font-weight: bold; color:#0e5499">{{data.observations}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="row">
        <div class="col-6"></div>
        <div class="col-6 bg-light text-center border border-dark border-padding"> 

          <table class="align-middle table-sm table table-border m-0 pb-0">
          <tbody>
            <tr>
              <td style="font-weight: bold"><b>Representative</b></td>
              <td style="font-weight: bold"><b>Date</b></td>
            </tr>
            <tr>
              <td class="m-0" style="font-weight: bold; color:#0e5499; font-size:12px"><b v-if="data.representative!=null">{{data.representative.name}}</b></td>
              <td style="font-weight: bold; color:#0e5499; font-size:12px"><b v-if="data.contactDate!=null">{{formatDate(data.contactDate.substr(0,10))}}</b></td>
            </tr>
          </tbody>
        </table>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import corporate from "@/services/api/corporate";
import { directive as onClickaway } from 'vue-clickaway';

export default {
  data() {
    return {
      fullscreenLoading: true,
      data: [],
      pages: []
    };
  },
  mounted() {
    corporate.getPdf(this.$route.params.id).then(response => {
      console.log('ESTE ES EL RESPONSE -> ', response);
      this.data = response;
      var pageinfo = [null,null,null,null,null]; //[sh1,sh2,sh3,Former Owner, LandLord]
      var page=[];
      var infoshareholder= {
        name: "",
        title: "",
        securitysocial: "",
        address: "",
        city: {
          name:"",
          state:{
            name:""
          }
        },
        state: "",        
        zip: "",
        pivot: {
          share:null
        },
        email: "",
        phone: "",
        birthDay: "",
        language:""
      }

      var infolandlord={
        name: "",
        address: "",
        city: {
          name:"",
          state:{
            name:""
          }
        },
        state: "",
        zip: "",
        phone: "",
        mobile: "",
        email: "",
        note: "",
      }

      var infoformerowner={
        name: "",
        address: "",
        city: {
          name:"",
          state:{
            name:""
          }
        },
        state: "",
        zip: "",
        phone: "",
        mobile: "",
        email: "",
        note: "",
      }

      var index=0;

      for (var i=0; i<Math.floor(response.shareholders.length/3+1); i++) {
        for (var j = 0; j < 3; j++) {
          if(index<response.shareholders.length){
            pageinfo.splice(j, 1, response.shareholders[index]);
          }else{
            pageinfo.splice(j, 1, infoshareholder);
          }
          index++;
        }
        if(i===0){
          if(response.formerowner!=null){
            pageinfo.splice(3, 1, response.formerowner);
          }else{
            pageinfo.splice(3, 1, infoformerowner);
          }
          if(response.landlord!=null){
            pageinfo.splice(4, 1, response.landlord);
          }else{
            pageinfo.splice(4, 1, infolandlord);
          } 
        }else{
          pageinfo.splice(3, 1, infolandlord);
          pageinfo.splice(4, 1, infoformerowner);
        }
        this.pages.push(Object.assign([], pageinfo));
      }
      this.download();
    });
  },
  methods: {
    formatDate(datestr){
      if(datestr!=='' && datestr!==null){
        const date = new Date(datestr)
        date.setDate(date.getDate() + 1);
        const dateTimeFormat = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }) 
        const [{ value: month },,{ value: day },,{ value: year }] = dateTimeFormat .formatToParts(date ) 

        return `${month}/${day}/${year }`;
      }else{
        return null;
      }
    },
    download() {
      this.fullscreenLoading = false;
      setTimeout(() => {
        this.$nextTick(() => {
          window.print();
        });
      }, 1000);
    }
  }
};
</script>

<style lang="scss">

.pageContainer{
  margin-left: 30px;
  margin-right: 30px;
}

.pages{
  
}

table {
  border-collapse: separate; /* the default option */
  border-spacing: 0; /* remove border gaps */
}


tr {
   line-height: 10px;
   min-height: 10px;
   height: 10px;
}


.bt {
  border-top: 1px dotted black !important;
}

.bb{
  border-bottom: 1px dotted black !important;
}

td{
  font-size: 9px;
}

.border-padding{
  padding-top: 2px;
  padding-bottom: 2px;
}


@page 
    {
        size: auto;   /* auto is the initial value */
        margin: 0mm;  /* this affects the margin in the printer settings */
        page-break-after: always !important;
        
    }



</style>